import { cn } from "@/lib/utils";

export function CoverImage(props: { src: string; className?: string; contain?: boolean }) {
  return (
    <div
      className={cn(
        props.className,
        "bg-cover bg-no-repeat bg-center rounded-md",
        props.contain ? "bg-contain" : "bg-cover"
      )}
      style={{ backgroundImage: `url('${props.src}')` }}
    ></div>
  );
}
