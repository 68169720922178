import * as React from "react";
import { cn } from "./utils";
import "./SelectNative.css";
import { ChevronDown } from "lucide-react";

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  variant?: "default" | "outline";
  invalid?: boolean;
}

const SelectNative = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, variant, invalid, ...props }, ref) => {
    return (
      <div className={cn("relative w-full flex items-center rounded-md", variant !== "outline" && "bg-white")}>
        <div className="flex-shrink-0 absolute right-2 top-0 bottom-0 flex items-center">
          <ChevronDown size={16} strokeWidth={1.8} />
        </div>
        <select
          className={cn(
            "h-9 pl-3 pr-7 py-1 bg-transparent rounded-md border border-input text-sm shadow-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:border-gray-700 disabled:cursor-not-allowed disabled:opacity-50 appearance-none cursor-pointer z-10 w-full",
            className,
            invalid && "border-red-500 focus-visible:border-red-500"
          )}
          ref={ref}
          {...props}
          autoComplete={props.autoComplete || "off"}
        >
          {props.children}
        </select>
      </div>
    );
  }
);
SelectNative.displayName = "Select";

export { SelectNative };
