import { TFunction } from "i18next";
import { z } from "zod";

export function useZodDefaultErrors(t: TFunction) {
  z.setErrorMap((issue, ctx) => {
    if (issue.code === z.ZodIssueCode.too_small) {
      if (issue.type === "number") {
        return {
          message: t("common_error_minNumber", "A minimum of {{min}} is expected", {
            min: issue.minimum,
          }),
        };
      } else {
        return {
          message: t("common_error_minLength", "At least {{min}} characters expected", {
            min: issue.minimum,
          }),
        };
      }
    }

    if (issue.code === z.ZodIssueCode.too_big) {
      if (issue.type === "number") {
        return {
          message: t("common_error_maxNumber", "A maximum of {{max}} is expected", {
            max: issue.maximum,
          }),
        };
      } else {
        return {
          message: t("common_error_maxLength", "A maximum of {{max}} characters allowed", {
            max: issue.maximum,
          }),
        };
      }
    }

    if (issue.code === "invalid_type" && (issue.received === "undefined" || issue.received === "nan")) {
      return { message: t("common_error_required") };
    }

    if (issue.code === z.ZodIssueCode.invalid_string) {
      if (issue.validation === "email") {
        return { message: t("common_error_invalidEmail", "Invalid email") };
      }
    }

    return { message: ctx.defaultError };
  });
}
