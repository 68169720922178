import { Card } from "@/components/ui/card";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { CameraIcon, GripVertical, LucideProps, PencilIcon, PlusIcon, Trash2 } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { NewProductModal } from "./NewProductModal";
import { useProducts } from "@/api/useProducts";
import { Separator } from "@/components/ui/separator";
import { t } from "i18next";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { useProductDelete } from "@/api/useProductDelete";
import { Button } from "@/components/ui/button";
import { CoverImage } from "@/components/ui/cover-image";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { ApiObjects } from "@pulso/api-client";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import type { Identifier, XYCoord } from "dnd-core";
import { cn } from "@/lib/utils";
import { withFacilityIdParam } from "@/lib/withParams";
import { useProductReorder } from "@/api/useProductsReorder";

export const ProductsPage = withFacilityIdParam(function ({ facilityId }) {
  const products = useProducts();
  const [productsReordered, setProductsReordered] = useState<ApiObjects["ProductDto"][]>([]);
  const reorderProductsMutation = useProductReorder(facilityId);

  useEffect(() => {
    if (products.data) {
      setProductsReordered(products.data);
    }
  }, [products.data]);

  const onCardMove = useCallback((dragIndex: number, hoverIndex: number) => {
    setProductsReordered((products) => {
      const copy = [...products];
      const temp = copy[dragIndex];
      copy[dragIndex] = copy[hoverIndex];
      copy[hoverIndex] = temp;
      return copy;
    });
  }, []);

  const onDragEnd = useCallback(() => {
    reorderProductsMutation.mutate({
      products: productsReordered.map((product, index) => ({ position: index, productId: product.id })),
    });
  }, [productsReordered]);

  return (
    <>
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">{t("products_title", "Products")}</h2>
        <p className="text-muted-foreground">
          {t("products_subtitle", "Define the products that you offer to clients")}
        </p>
      </div>
      <Separator className="my-6" />

      <NewProductModal>
        <Button>{t("products_addProduct_button", "Create new product")}</Button>
      </NewProductModal>

      <div className="mt-6">
        <DndProvider backend={HTML5Backend}>
          <Card>
            <div className="">
              {productsReordered.map((product, index) => (
                <DraggableProduct
                  key={product.id}
                  product={product}
                  index={index}
                  onCardMove={onCardMove}
                  onDragEnd={onDragEnd}
                />
              ))}
            </div>
          </Card>
        </DndProvider>
      </div>
    </>
  );
});

const Product = forwardRef<
  HTMLDivElement,
  { product: ApiObjects["ProductDto"]; hidden?: boolean; handlerId: Identifier | null }
>(function ({ product, hidden, handlerId }, ref) {
  const navigate = useNavigate();
  const deleteProduct = useProductDelete();

  return (
    <div
      onClick={() => navigate(`./${product.id}`)}
      className={cn(
        "flex items-center cursor-pointer space-x-3 border-b last-of-type:border-b-0 p-3",
        hidden && "invisible"
      )}
      ref={ref}
      data-handler-id={handlerId}
    >
      <div>
        <GripVertical strokeWidth={1.5} />
      </div>
      <div className="pl-3">
        {product.thumbUrl ? (
          <CoverImage src={product.thumbUrl} className="w-16 h-16" contain />
        ) : (
          <div className="w-16 h-16 flex items-center justify-center">
            <CameraIcon size={32} strokeWidth={1.2} />
          </div>
        )}
      </div>
      <div className="flex-1">{product.name}</div>
      <div className="text-end space-x-1">
        <ProductButton link={`./${product.id}`} tooltip={t("common_button_edit", "Edit")} icon={PencilIcon} />
        <ProductButton
          link={`../inventory/${product.id}`}
          tooltip={t("products_link_stockItems", "Inventory")}
          icon={PlusIcon}
        />
        <span onClick={(e) => e.stopPropagation()}>
          <ConfirmDialog
            title={t("products_deleteModal_title", "Delete {{product}}?", { product: product.name })}
            description={t("products_deleteModal_description", "Are you sure you want to delete {{product}}?", {
              product: product.name,
            })}
            onContinue={() => deleteProduct.mutate(product.id)}
          >
            <Button variant="ghost" size="sm" onClick={(e) => e.stopPropagation()}>
              <Trash2 size={22} strokeWidth={1.2} />
            </Button>
          </ConfirmDialog>
        </span>
      </div>
    </div>
  );
});

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function DraggableProduct({
  product,
  index,
  onCardMove,
  onDragEnd,
}: {
  product: ApiObjects["ProductDto"];
  index: number;
  onCardMove: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "product",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onCardMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "product",
    item: () => {
      return { id: product.id, index };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: onDragEnd,
  });

  drag(drop(ref));

  return <Product ref={ref} product={product} hidden={isDragging} handlerId={handlerId} />;
}

function ProductButton(props: { link: string; tooltip: string; icon: React.ForwardRefExoticComponent<LucideProps> }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link to={props.link}>
          <Button variant="ghost" size="sm" onClick={(e) => e.stopPropagation()}>
            <props.icon size={22} strokeWidth={1.2} />
          </Button>
        </Link>
      </TooltipTrigger>
      <TooltipContent>{props.tooltip}</TooltipContent>
    </Tooltip>
  );
}
